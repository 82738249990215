import { APP_NAMESPACE } from '@veeva-oncology-link/ui-common';

import { appConfig } from '@src/constants/appConfig';

if (!appConfig.isProdBuild) {
  window[APP_NAMESPACE] = {
    [appConfig.appName]: {
      appConfig: appConfig,
    },
  };
}

import('./bootstrap');
export {};
